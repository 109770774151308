import service from "@/api/service";
import { VisitGetRequest } from "@/api/analysis/visit/request";
import { VisitGetResponse } from "@/api/analysis/visit/response";

/**
 * 来店APIをコールします。
 *
 * @param getRequest 来店APIのリクエストボディ
 * @return VisitGetResponse
 */
export async function get(getRequest: VisitGetRequest) {
  const response = await service.post("/analysis-visit", getRequest);
  return response.data as VisitGetResponse;
}
