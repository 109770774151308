import service from "@/api/service";
import { VisitAttributeGetRequest } from "@/api/analysis/visit-attribute/request";
import { VisitAttributeGetResponse } from "@/api/analysis/visit-attribute/response";

/**
 * 来店属性APIをコールします。
 *
 * @param getRequest プロフィール属性(アプリDL)APIのリクエストボディ
 * @return VisitAttributeGetResponse
 */
export async function get(getRequest: VisitAttributeGetRequest) {
  const response = await service.post("/analysis-visit-attribute", getRequest);
  return response.data as VisitAttributeGetResponse;
}
