import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as VisitAttributeAPI from "@/api/analysis/visit-attribute";
import { isSuccess } from "@/api/response";
import { VisitAttributeGetRequest } from "@/api/analysis/visit-attribute/request";
import {
  VisitAttributeGetResponse,
  VisitAttributeGetResult
} from "@/api/analysis/visit-attribute/response";

const MODULE_NAME = "analysis/visit-attribute/get";

/**
 *  来店属性API（/analysis-visit-attribute）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: VisitAttributeGetRequest = {} as VisitAttributeGetRequest;
  getResponse: VisitAttributeGetResponse = {} as VisitAttributeGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getResult() {
    if (this.getResponse.results) {
      return this.getResponse.results;
    } else {
      return {} as VisitAttributeGetResult;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }
  // MutationActions
  @MutationAction
  async get(getRequest: VisitAttributeGetRequest) {
    const getResponse = await VisitAttributeAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as VisitAttributeGetResponse
    };
  }
}

export default getModule(Get);
